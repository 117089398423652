/* General */

body {
  background-color: #f5f5f5;
}

/* Main NavBar */

.navbar-home {
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 1px solid #ebebeb;
  background-color: #ffffff;
}

.app-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #686868;
  margin-top: 15px;
  text-transform: uppercase;
}

/* Main NavBar Buttons */

.download-pdf-button {
  height: 32px;
  width: 143px;
  background: #ffffff;
  color: #0f0f0f;
  border: 1px solid #1f1f1f;
  border-radius: 16px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.download-pdf-button:hover {
  background: #ffffff;
  color: #0f0f0f;
  border: 1px solid #1f1f1f;
  border-radius: 16px;
}

.download-pdf-button:active {
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #0f0f0f;
  --bs-btn-active-color: #0f0f0f;
}

.download-pdf-button-image {
  margin-right: 5px;
}

.login-button {
  height: 32px;
  min-width: 108px;
  background: #0f0f0f;
  border: 1px solid #0f0f0f;
  border-radius: 16px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.login-button:hover {
  background: #0f0f0f;
  border: 1px solid #0f0f0f;
  border-radius: 16px;
}

.login-button:active {
  --bs-btn-active-bg: #0f0f0f;
  --bs-btn-active-border-color: #0f0f0f;
}

.login-dropdown {
  height: 32px;
  min-width: 108px;
  background: #0f0f0f;
  border: 1px solid #0f0f0f;
  border-radius: 16px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  --bs-nav-link-color: #ffffff;
  --bs-nav-link-hover-color: #ffffff;
  --bs-navbar-active-color: #ffffff;
}

.languages-dropdown {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: black;
  width: 45px;
}

.dropdown-menu.show {
  min-width: auto;
}

a.active.dropdown-item {
  color: #ffffff;
  background-color: #0f0f0f;
}

.languages-dropdown-item:active {
  background-color: #ffffff;
  color: #0f0f0f;
}

/* General Info Section */

.general-info-section {
  background-color: #f5f5f5;
}

.general-info-title {
  font-weight: 700;
  font-size: 24px;
  color: black;
}

.form-general-info-container {
  display: flex;
  flex-wrap: wrap;
}

.form-group-general-info {
flex: 1;
margin-right: 30px; 
}

/* Remove arrows for number inputs - Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows for number inputs - Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.form-control {
  border-radius: 0;
}

.input-group-text {
  border-radius: 0;
}

/* UPS Section */

.ups-section {
  background-color: #ffffff;
}

.ups-tab-button {
  width: 5px;
  height: 5px;
  margin-left: 8px;
}

.ups-tab-span {
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  flex-direction: row;
}

.ups-section-title {
  font-weight: 700;
  font-size: 24px;
  color: black;
}

.ups-subsection-title {
  font-weight: 700;
  font-size: 18px;
  color: black;
}

.ups-section-row {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ups-input-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #686868;
  margin-top: 15px;
}

.ups-checkbox-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 14px; */
  color: #686868;
  margin-top: 15px;
}

.ups-input {
  max-width: 272px;
}

.ups-const {
  max-width: 272px;
  border-color: #ffffff;
}

.ups-const:disabled {
  background-color: #ffffff;
}

/* Graphs Section */

.calculations-section {
  background-color: #f5f5f5;
}

.graphs-section-title {
  font-weight: 700;
  font-size: 24px;
  color: black;
}

.graph-container {
  width: 100%;
  height: 300px;
  background: white;
  border: 2px solid #ccc;
  padding-top: 12px;
  padding-right: 20px;
  padding-bottom: 12px;
  padding-left: 20px;
  margin-bottom: 12px;
}

.co2-table-cell {
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
}