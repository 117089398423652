/* LoginPanel.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
}

.login-box-panel {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow effect */
}

.login-button-container {
  margin-top: 10px;
}